<template>
	<div class="authPage">
		<div
			class="
				content-top-bar
				d-flex
				justify-content-between
				align-items-center
				bg-white
			"
			:class="{
				'top-bar-fixed p-3': helpers.layout.isMobile(),
				'position-sticky p-4': !helpers.layout.isMobile(),
			}"
		>
			<router-link :to="{ name: 'auth.login' }" class="text-dark d-flex">
				<span class="material-icons-outlined" aria-label="back">
					arrow_back
				</span>
			</router-link>
		</div>
		<div
			:class="{
				'pb-86': helpers.layout.isMobile(),
				'height-minus-top-bar': !helpers.layout.isMobile(),
			}"
		>
			<div
				class="mx-auto"
				:class="{
					'w-100': helpers.layout.isMobile(),
					'w-50 pt-40': !helpers.layout.isMobile(),
				}"
			>
				<div
					class="header mb-3"
					:class="{
						'py-4 px-3': helpers.layout.isMobile(),
					}"
				>
					<h1 class="text-center mt-0">Magic Link</h1>

					<div class="alert alert-dark d-flex m-0" role="alert">
						<span class="material-icons-outlined me-2"> auto_awesome </span>
						<div>
							<p class="mb-0 fs-20px">
								Regardless of your usual sign-in process, we'll email you a
								special link that logs you in instantly.
							</p>
							<ol class="ps-3 mb-0 fs-20px">
								<li>
									Please use the correct email registered on
									<b>InteDashboard.</b>
								</li>
								<li>
									The link provided is <b>single-use</b> and
									<b>expires in 15 minutes.</b>
								</li>
							</ol>
						</div>
					</div>
				</div>
			</div>

			<kr-form
				ref="form"
				v-slot="{ meta, rules, data, isSubmitting }"
				:options="formOptions"
				class="needs-validation"
				autocomplete="false"
				novalidate
			>
				<div
					class="mx-auto"
					:class="{
						'w-100': helpers.layout.isMobile(),
						'w-50 min-h-100': !helpers.layout.isMobile(),
					}"
				>
					<div
						:class="{
							'p-3': helpers.layout.isMobile(),
						}"
					>
						<div class="mb-4">
							<kr-form-i
								v-slot="{ field, errors }"
								v-model="data.model.email"
								name="email"
								:rules="rules('email')"
								tooltip-message="This is the valid email address associated with your institution."
							>
								<label for="email_input" class="form-label">
									<span>
										Email<span class="text-danger" aria-hidden="true">*</span>
									</span>
									<!-- <span
										:class="{ isMobile: helpers.layout.isMobile() }"
										tooltip="This is the valid email address associated with your institution."
										class="material-icons-outlined"
										aria-hidden="true"
									>
										info
									</span> -->
								</label>
								<input
									id="email_input"
									type="email"
									class="form-control"
									v-bind="field"
									:class="{ 'is-invalid': errors[0] }"
									aria-describedby="email_error"
									aria-required="true"
									:aria-invalid="errors[0] ? true : false"
								/>
							</kr-form-i>
						</div>

						<div>
							<p class="d-inline-block">Didn’t receive a link? See our</p>
							<a
								href="https://help.intedashboard.com/i-am-unable-to-login"
								target="_blank"
								class="fw-bold"
							>
								Help Guides
							</a>
						</div>
					</div>
				</div>

				<div
					:class="{
						'position-fixed bottom-0 start-0 w-100': helpers.layout.isMobile(),
						'position-sticky bottom-0 pt-30': !helpers.layout.isMobile(),
					}"
				>
					<div
						v-if="helpers.layout.isMobile()"
						class="custom-btn-gradient bg-gradient"
					/>
					<div
						v-if="lastEmailSent"
						class="alert alert-success d-flex align-items-center rounded-0"
						role="alert"
						aria-live="assertive"
						aria-atomic="true"
					>
						<span class="material-icons-round me-1" aria-hidden="true">
							check_circle
						</span>
						<!-- Please check {{ lastEmailSent }} for password reset instructions. -->
						Please check your email for the magic link!
					</div>
					<button
						type="submit"
						class="w-100 btn btn-primary"
						:disabled="!meta.valid || !meta.dirty || isSubmitting"
						@click="send"
					>
						<template v-if="isSubmitting">
							<span
								class="spinner-border spinner-border-sm me-8px"
								role="status"
								aria-hidden="true"
							></span>
							Loading...
						</template>
						<template v-else> Email me the magic link </template>
					</button>
				</div>
			</kr-form>
		</div>
	</div>
</template>

<script>
import { ref, inject, onMounted } from "vue";
import { useRoute } from "vue-router";
export default {
	setup() {
		const $api = inject("$api");
		const helpers = inject("helpers");
		const _ = inject("_");
		const route = useRoute();

		const error = ref(route.query.error ? route.query.error : null);
		const form = ref(null);

		let lastEmailSent = ref("");
		const formOptions = ref({
			fields: {
				email: { value: "", rules: "email|required" },
			},
		});

		const send = () => {
			form.value.handleSubmit((values) => {
				return $api.auth.magic
					.sendEmail({ email: values.email })
					.then(function (response) {
						lastEmailSent.value = values.email;
						form.value.reset();
					})
					.catch(function (errors) {
						error.value = errors.response.status;
						lastEmailSent.value = "";
						form.value.setErrors(
							_.get(errors, "response.data.errors", {
								email: ["Something went wrong, please try again later."],
							})
						);
					});
			})();
		};
		onMounted(() => {
			if (error.value) {
				if (error.value == 401) {
					form.value.setErrors({
						email: ["Link is invalid or expired. Pls try again"],
					});
				}
			}
		});
		return {
			helpers,
			form,
			formOptions,
			send,
			lastEmailSent,
		};
	},
};
</script>
<style lang="scss" scoped>
.contentBody {
	height: calc(100% - 164px - 134px);
}

.contentBody-Success {
	height: calc(100% - 164px - 134px - 44px);
}
</style>
